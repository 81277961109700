import React, { useEffect } from 'react'

import { NotionRenderer } from 'react-notion-x'
// import 'react-notion-x/src/styles.css'
import './react-notion-x.css';

import 'prismjs/themes/prism-tomorrow.css'
import 'katex/dist/katex.min.css'
import { Code } from 'react-notion-x/build/third-party/code'
import { Collection } from 'react-notion-x/build/third-party/collection'
import { Equation } from 'react-notion-x/build/third-party/equation'
import { Modal } from 'react-notion-x/build/third-party/modal'
import { Pdf } from 'react-notion-x/build/third-party/pdf'

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import {sha256} from 'js-sha256'

// const apiUrl = "https://api.dushin.online/"
const apiUrl = "https://dushin.online:8443/"
// const apiUrl = "https://learn-node.azurewebsites.net/api/HttpTrigger1?code=4-JI5UvpjGKZZGLHG2XCYY6nsf8oThSFgb9uQGDnT4GkAzFuF1yAVg=="

const toDataURL = (url, id) => {
  var imageData = window.localStorage.getItem(id)

  if(!imageData) {
    return fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    ).then(data=>{
      window.localStorage.setItem(id, data)
      return data
    })
  } else {
    return new Promise((resolve)=>{
      resolve(imageData)
    })
  }
}

const ReactImage = (image) => {

  const [imgData, setImgData] = React.useState(null);

  var l = document.createElement("a");
  l.href = image.src;

  if(l.origin.includes("www.notion.so")) {
    return <img
      className={image.className} 
      src={image.src} 
    />
  }

  var id = sha256(l.origin+l.pathname)

  toDataURL(image.src, id).then(data=>{
    if(data) {
      if(data.includes("data:image")) {
        setImgData(data)
      } else {
        setImgData(image.src)
        window.localStorage.removeItem(id)
      }
      
    }
  }).catch(err=>{
    
  })

  if(imgData==null) {
    return <Box/>
  } else {
    return <img
      className={image.className} 
      src={imgData} 
    />
  }
  
}

const getBottomNav = () => {
  return fetch(
      apiUrl+"bottomNav",{
      method: 'GET'
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
}
const getPage = (pageId) => {
  var serialCode = window.localStorage.getItem('serialCode');
  if(!serialCode) {
    serialCode = ""
  }

  return fetch(
      apiUrl+"page/"+pageId+"?serialCode="+serialCode,{
      method: 'GET'
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });

};

function App() {

  const [bottomNav, setBottomNav] = React.useState([]);
  const [pageContent, setPageContent] = React.useState(null);

  const [page, setPage] = React.useState(null);

  const [openCodeDialog, setOpenCodeDialog] = React.useState(false);
  const [serialCode, setSerialCode] = React.useState('');

  const [nextPageId, setNextPageId] = React.useState('');

  useEffect(()=>{
    const videos = document.querySelectorAll('video');
    if(videos){
      videos.forEach(video=>{
        video.controlsList = "nodownload"
        video.oncontextmenu = () => {
          return false
        }
      })
    }
  }, [pageContent])

  const nearestAncestorHref = (node) => {
    while(node && !node.href) node=node.parentNode
    return node && node.href
  }

  useEffect(()=>{
    document.body.onclick = (e) => {
      var target = e.target;

      // var href
      // var className = target.className.toString()
      // console.log('className '+className)
      // if(className=="notion-page-title-text") {
      //   href = target.parentNode.parentNode.href
      // } else if (className.includes("notion-page-title-icon")) {
      //   href = target.parentNode.parentNode.parentNode.href
      // } else if (className.includes("notion-page-link")) {
      //   href = target.href
      // } else if (className=="notion-page-title") {
      //   href = target.parentNode.href
      // } else if (className=="title") {
      //   href = target.parentNode.href
      // } else if (className=="icon notion-page-icon") {
      //   href = target.parentNode.parentNode.href
      // } else if (className=="breadcrumb") {
      //   href = target.href
      // } else if (className.includes("SVGAnimatedString")) {
      //   return false
      // }

      var href = nearestAncestorHref(target)
      
      if(href!=null) {
        if(new URL(href).host==window.location.host) {
          var path = href.split("/")
          var pageId = path[path.length-1]
          getPage(pageId).then(result=>{
            if(result.code) {
              if(result.code==999) {
                setNextPageId(pageId)
                setOpenCodeDialog(true)
              }
            } else {
              setPageContent(result)
            }
          })
          return false
        }
      }
    }
  }, [])

  useEffect(()=>{

    getBottomNav().then(result=>{
      if(result){
        setBottomNav(result)
        setPage(0)
      }
    })


  }, [])

  useEffect(()=>{
    if(page!=null) {
      if(bottomNav[page]) {
        getPage(bottomNav[page].pageId).then(result=>{
          setPageContent(result)
        })
      }
    }


  }, [page])


  return (
    <Box className="App">
      {
        pageContent==null?
        <Box/>
        :
        <NotionRenderer 
          recordMap={pageContent} 
          fullPage={true} 
          darkMode={false}
          forceCustomImages={true}
          components={{
            Code,
            Collection,
            Equation,
            Modal,
            Pdf,
            nextImage:ReactImage
          }} />
      }
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
          style={{
            height:46
          }}
        >
          {
            bottomNav.map(item=>{
              return <BottomNavigationAction label={item.label} />
            })
          }
        </BottomNavigation>
      </Paper>
      
      <Dialog open={openCodeDialog} onClose={()=>{
        setOpenCodeDialog(false)
      }}>
        <DialogTitle>請填入序號</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            請填入序號
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            label="序號"
            type="text"
            fullWidth
            variant="standard"
            onChange= {(event)=>{
              setSerialCode(event.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            if(serialCode=='') {
              return
            }
            window.localStorage.setItem('serialCode', serialCode);
            setOpenCodeDialog(false)
            getPage(nextPageId).then(result=>{
              if(result.code) {
                if(result.code==999) {
                  setOpenCodeDialog(true)
                }
              } else {
                setNextPageId("")
                setPageContent(result)
              }
            })
          }}>確定</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default App;
